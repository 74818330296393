//authorization
export const AUTH_AUTHENTICATING = 'AUTH_AUTHENTICATING';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_USER_DATA = 'SET_USER_DATA';
export const UPDATE_USER_PROPERTY = 'UPDATE_USER_PROPERTY';
/**
 * @deprecated Use `UPDATE_USER_PROPERTY` instead
 */
export const SAVE_USER_SETTINGS = 'SAVE_USER_SETTINGS';

// Definition
export const DEFINITION_CITY_LIST_WILL_FETCH = 'DEFINITION_CITY_LIST_WILL_FETCH';
export const DEFINITION_CITY_LIST_DID_FETCH = 'DEFINITION_CITY_LIST_DID_FETCH';

export const DEFINITION_DEFAULT_SERVICES_WILL_FETCH = 'DEFINITION_DEFAULT_SERVICES_WILL_FETCH';
export const DEFINITION_DEFAULT_SERVICES_DID_FETCH = 'DEFINITION_DEFAULT_SERVICES_DID_FETCH';

export const DEFINITION_PAYMENT_METHODS_WILL_FETCH = 'DEFINITION_PAYMENT_METHODS_WILL_FETCH';
export const DEFINITION_PAYMENT_METHODS_DID_FETCH = 'DEFINITION_PAYMENT_METHODS_DID_FETCH';

export const DEFINITION_SHOP_STAFF_SCALE_LIST_WILL_FETCH = 'DEFINITION_SHOP_STAFF_SCALE_LIST_WILL_FETCH';
export const DEFINITION_SHOP_STAFF_SCALE_LIST_DID_FETCH = 'DEFINITION_SHOP_STAFF_SCALE_LIST_DID_FETCH';

// Customer
export const GET_CUSTOMER_INFORMATION = 'GET_CUSTOMER_INFORMATION';

// Shop constants
export const GET_SHOP_INFORMATION = 'GET_SHOP_INFORMATION';
export const SAVE_SHOP_DATA = 'SAVE_SHOP_DATA';
export const DELETE_STAFF = 'DELETE_STAFF ';
export const SET_STAFF_RIGHTS = 'SET_STAFF_RIGHTS';

// Staff
export const STAFF_DID_GET = 'STAFF_DID_GET';
export const GET_STAFF_ALBUMS = 'GET_STAFF_ALBUMS';
export const STAFF_ALBUM_WILL_FETCH = 'STAFF_ALBUM_WILL_FETCH';
export const STAFF_ALBUM_DID_FETCH = 'STAFF_ALBUM_DID_FETCH';
export const STAFF_ALBUM_DID_CREATE = 'STAFF_ALBUM_DID_CREATE';
export const STAFF_ALBUM_DID_DELETE = 'STAFF_ALBUM_DID_DELETE';
export const UPDATE_SPECIFIC_STAFF_ALBUM = 'UPDATE_SPECIFIC_STAFF_ALBUM ';
export const STAFF_ALBUM_PHOTO_WILL_FETCH = 'STAFF_ALBUM_PHOTO_WILL_FETCH';
export const STAFF_ALBUM_PHOTO_DID_FETCH = 'STAFF_ALBUM_PHOTO_DID_FETCH';

// Products
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const BUY_PRODUCT = 'BUY_PRODUCT';

//Checkout
export const GET_CHECKOUT_REQUESTS = 'GET_CHECKOUT_REQUESTS';

// Chat
export const GET_ALL_CHATS = 'GET_ALL_CHATS';
export const CHOOSE_CHAT = 'CHOOSE_CHAT';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const RECIEVE_MESSAGE = 'RECIEVE_MESSAGE';
export const CLOSE_CHAT = 'CLOSE_CHAT';

//Services types
export const GET_ALL_SERVICE_TYPES = 'GET_ALL_SERVICE_TYPES';
export const GET_CREATION_SERVICE_TYPES = 'GET_CREATION_SERVICE_TYPES';
export const CREATE_SERVICE_TYPE = 'CREATE_SERVICE_TYPE';

//Services
export const GET_ALL_SERVICES = 'GET_ALL_SERVICES';
export const GET_FIXED_PRICE_SERVICES = 'GET_FIXED_PRICE_SERVICES';
export const SHOW_SERVICE = 'SHOW_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const GET_RECOMMENDED = 'GET_RECOMMENDED';
export const CREATE_SERVICE = 'CREATE_SERVICE';
export const GET_BOOKING_SERVICES = 'GET_BOOKING_SERVICES';

// Shop News
export const SHOP_NEWS_LIST_WILL_INVALIDATE = 'SHOP_NEWS_LIST_WILL_INVALIDATE';
export const SHOP_NEWS_WILL_FETCH = 'SHOP_NEWS_WILL_FETCH';
export const SHOP_NEWS_DID_FETCH = 'SHOP_NEWS_DID_FETCH';
export const SHOP_NEWS_WILL_GET = 'SHOP_NEWS_WILL_GET';
export const SHOP_NEWS_DID_GET = 'SHOP_NEWS_DID_GET';
export const SHOP_NEWS_DID_CREATE = 'SHOP_NEWS_DID_CREATE';
export const SHOP_NEWS_DID_UPDATE = 'SHOP_NEWS_DID_UPDATE';
export const SHOP_NEWS_DID_DESTROY = 'SHOP_NEWS_DID_DESTROY';

// Shop Review
export const SHOP_REVIEW_WILL_FETCH_FIRST_PAGE = 'SHOP_REVIEW_WILL_FETCH_FIRST_PAGE';
export const SHOP_REVIEW_DID_FETCH_FIRST_PAGE = 'SHOP_REVIEW_DID_FETCH_FIRST_PAGE';
export const SHOP_REVIEW_WILL_FETCH_NEXT_PAGE = 'SHOP_REVIEW_WILL_FETCH_NEXT_PAGE';
export const SHOP_REVIEW_DID_FETCH_NEXT_PAGE = 'SHOP_REVIEW_DID_FETCH_NEXT_PAGE';
export const GET_REVIEW = 'GET_REVIEW';

// ReviewTable
export const GET_BOOKINGS_FOR_CALENDAR = 'GET_BOOKINGS_FOR_CALENDAR';
export const GET_STAFF_BOOKINGS_FOR_CALENDAR = 'GET_STAFF_BOOKINGS_FOR_CALENDAR';

// Gallery shop
export const ALBUM_PHOTO_WILL_FETCH = 'ALBUM_PHOTO_WILL_FETCH';
export const GET_ALBUM_PHOTOS = 'GET_ALBUM_PHOTOS'; // TODO Rename to ALBUM_PHOTO_DID_FETCH
export const ALBUM_WILL_FETCH = 'ALBUM_WILL_FETCH';
export const SHOW_ALL_ALBUMS = 'SHOW_ALL_ALBUMS'; // TODO Rename to ALBUM_DID_FETCH
export const REVIEW_ALBUM = 'REVIEW_ALBUM;';
export const DELETE_ALBUM = 'DELETE_ALBUM';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';
export const CREATE_ALBUM = 'CREATE_ALBUM';
export const NEXT_PAGE_ALBUM = 'NEXT_PAGE_ALBUM';

//Shop settings staff management
export const SHOP_STAFF_LIST_DID_FETCH = 'SHOP_STAFF_LIST_DID_FETCH';
export const SHOP_STAFF_LIST_WILL_FETCH = 'SHOP_STAFF_LIST_WILL_FETCH';
export const GET_SHOP_STUFF = 'GET_SHOP_STUFF';
export const SEND_INVITE_TO_STUFF = 'SEND_INVITE_TO_STUFF';
export const DELETE_STUFF = 'DELETE_STUFF';

// Sharings
export const SHOW_SHARING = 'SHOW_SHARING';
export const SHOW_ALL_SHARINGS = 'SHOW_ALL_SHARINGS';

//Search
export const SEARCH_SHOPS = 'SERCH_SHOPS';

// Landing Page
export const LANDING_PAGE_WILL_FETCH = 'LANDING_PAGE_WILL_FETCH';
export const LANDING_PAGE_DID_FETCH = 'LANDING_PAGE_DID_FETCH';
export const LANDING_PAGE_WILL_INVALIDATE = 'LANDING_PAGE_WILL_INVALIDATE';

// main page
export const SITE_MOST_REVIEWED_SHOP_WILL_FETCH = 'SITE_MOST_REVIEWED_SHOP_WILL_FETCH';
export const SITE_MOST_REVIEWED_SHOP_DID_FETCH = 'SITE_MOST_REVIEWED_SHOP_DID_FETCH';
export const SITE_LATEST_REVIEW_WILL_FETCH = 'SITE_LATEST_REVIEW_WILL_FETCH';
export const SITE_LATEST_REVIEW_DID_FETCH = 'SITE_LATEST_REVIEW_DID_FETCH';

//notifications
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

// site busy count
export const SITE_BUSY_COUNT_WILL_INCREMENT = 'SITE_BUSY_COUNT_WILL_INCREMENT';

// site linkables
export const SITE_LINKABLE_ARTICLE_WILL_FETCH = 'SITE_LINKABLE_ARTICLE_WILL_FETCH';
export const SITE_LINKABLE_ARTICLE_DID_FETCH = 'SITE_LINKABLE_ARTICLE_DID_FETCH';
export const SITE_LINKABLE_LANDING_WILL_FETCH = 'SITE_LINKABLE_LANDING_WILL_FETCH';
export const SITE_LINKABLE_LANDING_DID_FETCH = 'SITE_LINKABLE_LANDING_DID_FETCH';

//Promotion
export const SEND_PROMTION_REQUEST = 'SEND_PROMTION_REQUEST';
export const GET_PROMOTION_LIST = 'GET_PROMOTION_LIST';
export const GET_PROMOTION_PAGE = 'GET_PROMOTION_PAGE';

// Bank's card
export const GET_CARDS = 'GET_CARDS;';

// Pagination
export const GET_ITEM_LIST = 'GET_ITEM_LIST';

// admin
export const
  ADMIN_USERS_DID_FETCH = 'ADMIN_USERS_DID_FETCH',
  ADMIN_USER_DID_UPDATE = 'ADMIN_USER_DID_UPDATE',
  GET_USER = 'GET_USER',
  ADMIN_BOOKINGS_DID_FETCH = 'ADMIN_BOOKINGS_DID_FETCH',
  GET_USER_BOOKINGS = 'GET_USER_BOOKINGS',
  GET_INVOICES = 'GET_INVOICES',
  GET_INVOICE_BOOKINGS = 'GET_INVOICE_BOOKINGS',
  ADMIN_REVIEWS_DID_FETCH = 'ADMIN_REVIEWS_DID_FETCH',
  ADMIN_REVIEW_DID_UPDATE = 'ADMIN_REVIEW_DID_UPDATE',
  ADMIN_INVOICES_DID_FETCH = 'ADMIN_INVOICES_DID_FETCH',
  ADMIN_INVOICE_WILL_FETCH_AMOUNT = 'ADMIN_INVOICE_WILL_FETCH_AMOUNT',
  ADMIN_INVOICE_DID_FETCH_AMOUNT = 'ADMIN_INVOICE_DID_FETCH_AMOUNT',
  ADMIN_INVOICE_DID_UPDATE = 'ADMIN_INVOICE_DID_UPDATE';

export const
  ADMIN_DEFAULT_SERVICES_WILL_REORDER = 'ADMIN_DEFAULT_SERVICES_WILL_REORDER',
  ADMIN_DEFAULT_SERVICES_DID_REORDER = 'ADMIN_DEFAULT_SERVICES_DID_REORDER';

export const ADMIN_DEFAULT_SERVICE_WILL_FETCH = 'ADMIN_DEFAULT_SERVICE_WILL_FETCH',
  ADMIN_DEFAULT_SERVICE_DID_FETCH = 'ADMIN_DEFAULT_SERVICE_DID_FETCH',
  ADMIN_DEFAULT_SERVICE_WILL_CREATE = 'ADMIN_DEFAULT_SERVICE_WILL_CREATE',
  ADMIN_DEFAULT_SERVICE_DID_CREATE = 'ADMIN_DEFAULT_SERVICE_DID_CREATE',
  ADMIN_DEFAULT_SERVICE_WILL_UPDATE = 'ADMIN_DEFAULT_SERVICE_WILL_UPDATE',
  ADMIN_DEFAULT_SERVICE_DID_UPDATE = 'ADMIN_DEFAULT_SERVICE_DID_UPDATE';

export const ADMIN_DEFAULT_SERVICE_TREE_WILL_FETCH = 'ADMIN_DEFAULT_SERVICE_TREE_WILL_FETCH',
  ADMIN_DEFAULT_SERVICE_TREE_DID_FETCH = 'ADMIN_DEFAULT_SERVICE_TREE_DID_FETCH';

export const
  ADMIN_PAYMENT_METHODS_WILL_REORDER = 'ADMIN_PAYMENT_METHODS_WILL_REORDER',
  ADMIN_PAYMENT_METHODS_DID_REORDER = 'ADMIN_PAYMENT_METHODS_DID_REORDER';

  export const
  ADMIN_PAYMENT_METHOD_WILL_FETCH = 'ADMIN_PAYMENT_METHOD_WILL_FETCH',
  ADMIN_PAYMENT_METHOD_DID_FETCH = 'ADMIN_PAYMENT_METHOD_DID_FETCH',
  ADMIN_PAYMENT_METHOD_WILL_CREATE = 'ADMIN_PAYMENT_METHOD_WILL_CREATE',
  ADMIN_PAYMENT_METHOD_DID_CREATE = 'ADMIN_PAYMENT_METHOD_DID_CREATE',
  ADMIN_PAYMENT_METHOD_WILL_UPDATE = 'ADMIN_PAYMENT_METHOD_WILL_UPDATE',
  ADMIN_PAYMENT_METHOD_DID_UPDATE = 'ADMIN_PAYMENT_METHOD_DID_UPDATE';
