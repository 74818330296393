import { useTranslation } from 'react-i18next';


import { BookingReview } from 'src/models/entity';

import { FormatDateTime } from 'src/Components/Common/DateTime';

import BookingReviewRatings from 'src/Components/Common/RatingStars/BookingReviewRatings';
import { CustomerBadge } from 'src/Components/Common/Customer';
import { ShopAvatar } from 'src/Components/Common/Shop';
import { LineBreakable } from 'src/Components/Common/Text';


interface Props {
  shopAvatar: string;
  review: BookingReview;
  onRedirect: (reviewId: string) => void;
}

function ReviewItem({
  shopAvatar,
  review,
  onRedirect,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="review-container">
      <div className="row">
        <div className="col-xl-2">
          {review.customer && <CustomerBadge customer={review.customer} />}
        </div>

        <div className="col-xl-7" onClick={() => onRedirect(review.id)}>
          <p className="review-title">{review.title}</p>
          <p className="review-info">
            <FormatDateTime value={review.created_at} />
          </p>

          <div onClick={() => onRedirect(review.id)}>
            <LineBreakable className="review-content">
              {review.review}
            </LineBreakable>
          </div>

          <div className="sharing-photo">
            {review.photos?.map((photo, index) => (
              <div className="sharing-photo__item" key={index}>
                <div className="news-prev-img">
                  <img
                    src={photo.url}
                    alt={photo.url.slice(photo.url.lastIndexOf('/') + 1)}
                  />
                </div>
              </div>
            ))}
          </div>

          {review.reply && (
            <div className="review-answer">
              <p className="review-answer__title">
                {t('shopPublic.review.shopAnswer')}
              </p>
              <div className="answer__img">
                {shopAvatar && <ShopAvatar src={shopAvatar} />}
              </div>
              <div onClick={() => onRedirect(review.id)}>
                <LineBreakable className="review-answer__text">
                  {review.reply}
                </LineBreakable>
              </div>
            </div>
          )}
        </div>

        <div className="col-xl-3">
          <BookingReviewRatings ratings={review.review_average_rating} />
        </div>
      </div>
    </div>
  );
};

export default ReviewItem;
