import React, { Component } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';


import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';
import SiteContainer from '../Global/SiteContainer/SiteContainer';

import AnnouncementMarquee from 'src/Components/Pages/ShopPublicPage/AnnouncementMarquee/AnnouncementMarquee';

import ShopMainPageNav from './Navigation/ShopMainPageNav';
import ShopOverview from './ShopOverview/ShopOverview';
import NewsList from './ShowNews/NewsList';
import NewsDetail from './ShowNews/NewsDetail';
import Stylist from './Stylist/Stylist';
// import GeneralPublicProducts from './PublicProductPage/GeneralPublicProducts';
// import PublicProductView from './PublicProductPage/PublicProductView';
import PriceList from './PriceList/PriceList';
import Albums from './Album/Albums';
import AlbumDetail from './Album/AlbumDetail';

import ReviewList from './Review/ReviewList';
import ReviewDetail from './Review/ReviewDetail';


class UserTypeView extends Component {

  state = {
    pageName: 'Overview',
  };

  getPageName = page => {
    this.setState({
      pageName: page,
    });
  };

  setDirTitles = dir => {
    let res = [];
    switch (dir) {
      case 'product':
        res = ['PRODUCTS', 'PRODUCT'];
        break;
      case 'gallery':
        res = ['GALLERY', 'ALBUM'];
        break;
      case 'news':
        res = ['ALL NEWS', 'NEWS'];
        break;
      case 'review':
        res = ['REVIEWS', 'REVIEW'];
        break;
      default:
        break;
    }
    return res;
  };

  setRoutes = () => {
    const {
      shop,
      i18n,
    } = this.props;

    const path = window.location.pathname;
    const lastI = path.lastIndexOf('/');
    const pathToMain = `${path.slice(0, path.slice(0, lastI).lastIndexOf('/'))}/main`;
    const pathToDir = path.slice(0, lastI).slice(path.slice(0, lastI).lastIndexOf('/') + 1);
    const mainDir = { title: shop && shop.user_type_data && shop.user_type_data.name, path: pathToMain };
    const titles = this.setDirTitles(pathToDir);
    const parentDir = {
      title: titles[0],
      path: `${path.slice(0, lastI)}`,
    };
    const childDir = {
      title: titles[1],
      path: path,
    };

    if (titles.length === 0) {
      return [
        {
          title: shop && shop.user_type_data && i18n.language === 'zh' ? shop.user_type_data.chineseName || shop.user_type_data.name : shop.user_type_data.name,
          path: `${path.slice(0, lastI)}/main`,
        },
        {
          title: path.slice(lastI + 1),
          path: path,
        },
      ];
    } else {
      return [mainDir, parentDir, childDir];
    }
  };

  render() {
    const routes = this.setRoutes();
    const {
      match,
      shop,
    } = this.props;

    return (
      <SiteContainer>
        <div className="container">
          <div className="grey-block" />
        </div>

        <div className="container">
          <AnnouncementMarquee />

          <Breadcrumb routes={routes} />

          <ShopMainPageNav
            shop={shop}
          />
        </div>

        <Switch>
          <Route
            path="/shop/:id/main"
            render={() => <ShopOverview getPageName={this.getPageName} />}
          />

          <Route
            exact
            path="/shop/:id/news"
            render={() => <NewsList getPageName={this.getPageName} />}
          />
          <Route
            path="/shop/:id/news/:newsId"
            render={() => <NewsDetail />}
          />

          <Route
            path="/shop/:id/stylist"
            render={() => <Stylist shopId={match.params.id} getPageName={this.getPageName} />}
          />

          {/*       Hidden since ?
          <Route
            exact
            path="/shop/:id/product"
            render={() => <GeneralPublicProducts shopId={match.params.id} getPageName={this.getPageName} />}
          />
          <Route
            path="/shop/:id/product/:productId"
            render={() => <PublicProductView shopId={match.params.id} />}
          />
*/}

          <Route
            exact
            path="/shop/:id/review"
            render={() => <ReviewList shopId={match.params.id} />}
          />
          <Route
            path="/shop/:id/review/:reviewId"
            render={() => <ReviewDetail />}
          />

          <Route
            exact
            path="/shop/:id/gallery"
            render={() => <Albums shopId={match.params.id} getPageName={this.getPageName} />}
          />
          <Route
            path="/shop/:id/gallery/:albumId"
            render={() => <AlbumDetail shopId={match.params.id} />}
          />

          <Route
            path="/shop/:id/price-list"
            render={() => <PriceList getPageName={this.getPageName} />}
          />

          {/* Default / Fallback route */}
          <Redirect to={`/shop/${match.params.id}/main`} />
        </Switch>
      </SiteContainer>
    );
  }
}

export default withTranslation()(
  withRouter(UserTypeView)
);
