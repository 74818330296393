import {
  SHOP_REVIEW_WILL_FETCH_FIRST_PAGE,
  SHOP_REVIEW_DID_FETCH_FIRST_PAGE,
  SHOP_REVIEW_WILL_FETCH_NEXT_PAGE,
  SHOP_REVIEW_DID_FETCH_NEXT_PAGE,
  GET_REVIEW,
} from '../../constants/constants';

const initialState = {
  subjectShopId: '',

  /**
   * The last time when a list of news is fetched
   */
  firstPageLastFetchedAt: null,

  isFetchingFirstPage: false,

  isFetchingNextPage: false,

  hasFetchedAllReviews: false,

  reviews: [],

  review: {},
};

const shopReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOP_REVIEW_WILL_FETCH_FIRST_PAGE:
      return {
        ...state,
        subjectShopId: action.payload.shopId,
        firstPageLastFetchedAt: null,
        isFetchingFirstPage: true,
        hasFetchedAllReviews: false,
        reviews: [],
      };
    case SHOP_REVIEW_DID_FETCH_FIRST_PAGE:
      return {
        ...state,
        firstPageLastFetchedAt: new Date(),
        isFetchingFirstPage: false,

        // adopt remote items
        reviews: action.payload.reviews,
      };
    case SHOP_REVIEW_WILL_FETCH_NEXT_PAGE:
      return {
        ...state,
        isFetchingNextPage: true,
      };
    case SHOP_REVIEW_DID_FETCH_NEXT_PAGE:
      return {
        ...state,
        isFetchingNextPage: false,

        // append the next page of items to the end
        reviews: state.reviews.concat(action.payload.reviews),

        hasFetchedAllReviews: action.payload.hasFetchedAllReviews,
      };
    case GET_REVIEW:
      return {
        ...state,
        review: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default shopReviewReducer;
