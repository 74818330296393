import { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';


import { BookingReview } from 'src/models/entity';

import { CustomerBadge } from 'src/Components/Common/Customer';
import { Ellipsize } from 'src/Components/Common/Text';


interface Props {
  // from parent
  className?: string;
  shopId: string;

  // from mapStateToProps
  reviews: BookingReview[];
}

function ShopMainReviewCardboard({ className = '', shopId, reviews }: Props) {
  const history = useHistory();

  const { t } = useTranslation();

  const redirectToReview = (review: BookingReview) => {
    history.push(`/shop/${shopId}/review/${review.id}`);
  };

  /**
   * Only display latest 2 reviews
   */
  const latestReviews = useMemo(() => reviews.slice(0, 2), [reviews]);

  return (
    <div className={`shop-group ${className}`}>
      <p className="theme-subtitle">{t('shopPublic.overview.latestReviews')}</p>

      {latestReviews.length > 0 ? (
        <>
          <div className="row">
            {latestReviews.map((review, index) => (
              <div className="col-lg-6" key={index}>
                {review.customer && (
                  <CustomerBadge
                    avatarSize="small"
                    className="mb-2"
                    customer={review.customer}
                    horizontal
                  />
                )}
                <Link
                  to={`/shop/${shopId}/review/${review.id}`}
                  className="review-title"
                >
                  {review.title}
                </Link>
                <div
                  className="latest-review-text"
                  onClick={() => redirectToReview(review)}
                  style={{ cursor: 'pointer' }}
                >
                  <p>
                    <Ellipsize text={review.review} maxlength={80} />
                  </p>
                </div>
                <div className="shop-gallery">
                  {review.photos?.slice(0, 3).map((photo, index) => (
                    <div
                      className="shop-gallery__item"
                      key={photo.id}
                      onClick={() => redirectToReview(review)}
                    >
                      <img src={photo.url} alt={`review_photo_${index}`} />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div>
            <Link to={`/shop/${shopId}/review`} className="more-link">
              {t('shopPublic.overview.more')}
            </Link>
          </div>
        </>
      ) : (
        <div className="latest-review-text">
          {t('shopPublic.overview.nothingToShow')}
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state: any) {
  return {
    reviews: state.shopReviewReducer.reviews,
  };
}

export default connect(mapStateToProps)(ShopMainReviewCardboard);
