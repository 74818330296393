import React, { Component } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import Breadcrumb from '../../Common/Breadcrumb/Breadcrumb';

import ShopSettingPageSideNav from '../ShopPrivatePage/ShopSettingPageSideNav';
import ShopMainPageNav from './Navigation/ShopMainPageNav';

import ShopOverview from './ShopOverview/ShopOverview';
import NewsList from './ShowNews/NewsList';
import NewsDetail from './ShowNews/NewsDetail';
import Stylist from './Stylist/Stylist';
// import GeneralPublicProducts from './PublicProductPage/GeneralPublicProducts';
// import PublicProductView from './PublicProductPage/PublicProductView';
import Albums from './Album/Albums';
import AlbumDetail from './Album/AlbumDetail';
import PriceList from './PriceList/PriceList';
import ReviewList from './Review/ReviewList';
import ReviewDetail from './Review/ReviewDetail';


class ShopTypeView extends Component {
  getPageName = page => {
    this.setState({
      pageName: page,
    });
  };

  setDirTitles = (dir, shortDir) => {
    let res = [];

    switch (dir) {
      case 'product':
        res = ['PRODUCTS', 'PRODUCT'];
        break;
      case 'gallery':
        res = ['GALLERY', 'ALBUM'];
        break;
      default:
        break;
    }

    switch (shortDir) {
      case 'news':
        res = ['ALL NEWS', 'NEWS'];
        break;
      case 'review':
        res = ['REVIEWS', 'REVIEW'];
        break;
      default:
        break;
    }

    return res;
  };

  setRoutes = () => {
    const {
      shop,
      i18n,
    } = this.props;

    const path = window.location.pathname;
    const lastI = path.lastIndexOf('/');
    const pathToMain = `${path.slice(0, path.slice(0, lastI).lastIndexOf('/'))}/main`;
    const pathToDir = path.slice(0, lastI).slice(path.slice(0, lastI).lastIndexOf('/') + 1);
    const shortPath = path.slice(path.slice(0, lastI).lastIndexOf('/') + 1, lastI);
    const mainDir = { title: shop && shop.user_type_data && i18n.language === 'zh' ? shop.user_type_data.chineseName || shop.user_type_data.name : shop.user_type_data.name, path: pathToMain };
    const titles = this.setDirTitles(pathToDir, shortPath);
    const parentDir = {
      title: titles[0],
      path: `${path.slice(0, path.lastIndexOf('/'))}`,
    };
    const childDir = {
      title: titles[1],
      path: path,
    };

    if (titles.length === 0) {
      return [
        {
          title: shop && shop.user_type_data && i18n.language === 'zh' ? shop.user_type_data.chineseName || shop.user_type_data.name : shop.user_type_data.name,
          path: `${path.slice(0, lastI)}/main`,
        },
        {
          title: path.slice(lastI + 1),
          path: path,
        },
      ];
    } else {
      return [mainDir, parentDir, childDir];
    }
  };

  render() {
    const routes = this.setRoutes();
    const {
      auth,
      shop,
    } = this.props;

    return (
      <div className="adminWrapper">
        <div className="adminBox">
          <ShopSettingPageSideNav />

          <div className="mainPanel">
            <div className="main-container">
              <div className="main-container__content">
                <Breadcrumb routes={routes} />

                <ShopMainPageNav
                  isWithSetting
                  shop={shop}
                />

                <Switch>
                  <Route
                    path="/shop/main"
                    render={() => <ShopOverview getPageName={this.getPageName} />}
                  />

                  <Route
                    exact
                    path="/shop/news"
                    render={() => <NewsList getPageName={this.getPageName} />}
                  />
                  <Route
                    path="/shop/news/:newsId"
                    render={() => <NewsDetail />}
                  />

                  <Route
                    path="/shop/stylist"
                    render={() => <Stylist shopId={auth.user.id} getPageName={this.getPageName} />}
                  />

{/*       Hidden since ?
                  <Route
                    exact
                    path="/shop/product"
                    render={() => <GeneralPublicProducts shopId={auth.user.id} getPageName={this.getPageName} />}
                  />
                  <Route
                    path={`/shop/product/:productId`}
                    render={() => <PublicProductView shopId={auth.user.id} />}
                  />
*/}

                  <Route
                    exact
                    path="/shop/review"
                    render={() => <ReviewList shopId={auth.user.id} />}
                  />
                  <Route
                    path="/shop/review/:reviewId"
                    render={() => <ReviewDetail />}
                  />

                  <Route
                    exact
                    path="/shop/gallery"
                    render={() => <Albums shopId={shop.id} getPageName={this.getPageName} />}
                  />
                  <Route
                    path="/shop/gallery/:albumId"
                    render={() => <AlbumDetail shopId={shop.id} />}
                  />

                  <Route
                    path="/shop/price-list"
                    render={() => <PriceList getPageName={this.getPageName} />}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withTranslation()(
  withRouter(
    connect(
      mapStateToProps
    )(ShopTypeView)
  )
);
