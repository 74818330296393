import { combineReducers } from 'redux';

import auth from './FeaturesReducers/auth';
import galleryReducer from './FeaturesReducers/galleryReducer';
import chatReducer from './ChatReducer/chatReducer';
import defaultServiceReducer from './DefinitionReducers/defaultServiceReducer';
import paymentMethodReducer from './DefinitionReducers/paymentMethodReducer';
import sharingReducer from './FeaturesReducers/sharingReducer';
import servicesReducer from './FeaturesReducers/servicesReducer';
import serviceTypesReducer from './FeaturesReducers/serviceTypesReducer';
import customerSettingsReducer from './CustomerReducer/customerSettingsReducer';
import bookingsReducer from './FeaturesReducers/bookingsReducer';
import shopNewsReducer from './FeaturesReducers/shopNewsReducer';
import shopReviewReducer from './FeaturesReducers/shopReviewReducer';
import ShopReducer from './ShopReducers/ShopReducer';
import GetStuffInformationReducer from './StaffReducer/GetStuffInformationReducer';
import galleryStaffReducer from './StaffReducer/galleryStaffReducer';
import shopProductReducer from './ShopReducers/shopProductReducer';
import checkoutReducer from './ShopReducers/checkoutReducer';
import searchReducer from './searchReducer';
import landingPageReducer from './MainPageReducer/landingPageReducer';
import promotionReducer from './PromotionReducers/promotionReducer';
import bankCardsReducer from './FeaturesReducers/bankCardsReducer';
import paginationReducer from './PaginationReducer/paginationReducer';
import adminReducer from './AdminReducers/adminReducer';
import adminBookingsReducer from './AdminReducers/adminBookingsReducer';
import adminDefaultServiceReducer from './AdminReducers/adminDefaultServiceReducer';
import adminDefaultServiceTreeReducer from './AdminReducers/adminDefaultServiceTreeReducer';
import adminInvoicesReducer from './AdminReducers/adminInvoicesReducer';
import adminPaymentMethodReducer from './AdminReducers/adminPaymentMethodReducer';
import adminReviewsReducer from './AdminReducers/adminReviewsReducer';
import adminUsersReducer from './AdminReducers/adminUsersReducer';
import cityReducer from './DefinitionReducers/cityReducer';
import shopStaffScaleReducer from './DefinitionReducers/shopStaffScaleReducer';
import siteBusyCountReducer from './SiteReducers/siteBusyCountReducer';
import siteLatestReviewReducer from './MainPageReducer/siteLatestReviewReducer';
import siteLinkableReducer from './SiteReducers/siteLinkableReducer';
import siteMostReviewedShopReducer from './MainPageReducer/siteMostReviewedShopReducer';

export default combineReducers({
  auth,
  galleryReducer,
  chatReducer,
  defaultServiceReducer,
  paymentMethodReducer,
  sharingReducer,
  servicesReducer,
  serviceTypesReducer,
  customerSettingsReducer,
  bookingsReducer,
  GetStuffInformationReducer,
  shopNewsReducer,
  shopReviewReducer,
  ShopReducer,
  galleryStaffReducer,
  shopProductReducer,
  checkoutReducer,
  searchReducer,
  landingPageReducer,
  promotionReducer,
  bankCardsReducer,
  paginationReducer,
  adminReducer,
  adminBookingsReducer,
  adminDefaultServiceReducer,
  adminDefaultServiceTreeReducer,
  adminInvoicesReducer,
  adminPaymentMethodReducer,
  adminReviewsReducer,
  adminUsersReducer,
  cityReducer,
  shopStaffScaleReducer,
  siteBusyCountReducer,
  siteLatestReviewReducer,
  siteLinkableReducer,
  siteMostReviewedShopReducer,
});

export function asyncActionDispatch(res, dispatch, method) {
  if (res.data.status === 'success') {
    dispatch(method(res.data.data));
  }
}
export function bekPidor(res, dispatch, method) {
  if (res.data.message === 'success') {
    dispatch(method(res.data.data));
  }
}
