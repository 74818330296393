import { BookingReview } from 'src/models/entity';

import { GetResponse } from 'src/models/api/response/GetResponse';
import {
  ListResponse,
  PaginatedListResponse,
} from 'src/models/api/response/ListResponse';

import { apiGet, apiPut } from '../api-utils';


export const getShopPublishedReviews = (
  shopId: string,
  since?: string,
  skipping?: string,
  limit?: number
) =>
  apiGet<ListResponse<BookingReview>>(`shop/${shopId}/reviews`, {
    since,
    skipping,
    limit,
  }).then((response) => response.data);

export const getShopReviewsForModeration = (page?: number) =>
  apiGet<PaginatedListResponse<BookingReview>>(`review-moderation`, {
    page,
  });

export const leaveReplyToReview = (reviewId: string, reply: string) =>
  apiPut<GetResponse<BookingReview>>(`review/${reviewId}/reply`, {
    reply,
  }).then((response) => response.data);
