import {
  SHOP_REVIEW_WILL_FETCH_FIRST_PAGE,
  SHOP_REVIEW_DID_FETCH_FIRST_PAGE,
  SHOP_REVIEW_WILL_FETCH_NEXT_PAGE,
  SHOP_REVIEW_DID_FETCH_NEXT_PAGE,
  GET_REVIEW,
} from 'src/Redux/constants/constants';

import api from 'src/api';
import { shopBookingReviewAPI } from 'src/utils/api';

import { isDataStillFresh } from 'src/Redux/redux-utils';


/**
 *
 * @param {string} shopId
 * @returns
 */
export const fetchFirstPageOfShopReviews = (shopId) => (dispatch, getState) => {
  // console.log('fetchFirstPageOfShopReviews shopId:', shopId);

  const prevState = getState().shopReviewReducer;

  if (shopId === prevState.subjectShopId) {
    // The specified shop's review list has been fetched previously and is still in state.

    if (prevState.isFetchingFirstPage) {
      // console.log(
      //   'Already fetching first page of shop reviews. Aborted.'
      // );
      return;
    } else if (
      prevState.firstPageLastFetchedAt &&
      isDataStillFresh(prevState.firstPageLastFetchedAt)
    ) {
      // console.log(
      //   'Previous shop review list is still fresh. No need to fire a fetch request. Aborted.'
      // );
      return;
    }
  }

  // TODO Upgrade axios and add code to cancel any previous ongoing axios request.

  dispatch(
    willFetchFirstPageOfShopReviews({
      shopId,
    })
  );

  // console.log('Fetch first page of reviews of shop', shopId);
  shopBookingReviewAPI.getShopPublishedReviews(shopId).then((reviews) => {
    dispatch(didFetchFirstPageOfShopReviews({ shopId, reviews }));
  });
};

/**
 *
 * @param {string} shopId
 * @param {string?} since
 * @param {string?} skipping
 * @returns
 */
export const fetchNextPageOfShopReviews = (shopId, since, skipping) => (dispatch) => {
  const limit = 10;

  dispatch(willFetchNextPageOfShopReviews());

  shopBookingReviewAPI
    .getShopPublishedReviews(shopId, since, skipping, limit)
    .then((reviews) => {
      dispatch(
        didFetchNextPageOfShopReviews({
          reviews,
          hasFetchedAllReviews: reviews.length < limit,
        })
      );
    });
};

export const getReview = (data) => (dispatch) =>
  api.shopBookings.getOneShopReview(data).then((res) => {
    if (res.data.message === 'success') {
      dispatch(GetReview(res.data.data));
      return { res, access: true };
    }
    return { res, access: false };
  });

const willFetchFirstPageOfShopReviews = ({ shopId }) => ({
  type: SHOP_REVIEW_WILL_FETCH_FIRST_PAGE,
  payload: { shopId },
});

const didFetchFirstPageOfShopReviews = ({ shopId, reviews }) => ({
  type: SHOP_REVIEW_DID_FETCH_FIRST_PAGE,
  payload: { shopId, reviews },
});

const willFetchNextPageOfShopReviews = () => ({
  type: SHOP_REVIEW_WILL_FETCH_NEXT_PAGE,
});

const didFetchNextPageOfShopReviews = ({ reviews, hasFetchedAllReviews }) => ({
  type: SHOP_REVIEW_DID_FETCH_NEXT_PAGE,
  payload: {
    reviews,
    hasFetchedAllReviews,
  },
});

const GetReview = (data) => ({
  type: GET_REVIEW,
  payload: data,
});
